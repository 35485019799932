<template>
  <div>
    <div
      class="d-sm-block d-mb-block d-lg-none"
      style="height: 100%; width: 100%; overflow-y: hidden;"
    >
      <div class="background-image-mobile">
        <!-- <img class="bg-mobile" src="../assets/ozo_landing-tablet.png" />
        <img class="bg-mobile-1" src="../assets/ozo_landing-mobile.png" /> -->
        <div class="logo-container-mobile">
            <img class="logo-ozo-mobile" src="../assets/ozo_logo-mobile.svg" />
          </div>
        <div class="content-container-mobile">
          <div class="content-container-mobile-2">
            <div>
              <p class="desc-header-mobile">
                Ozo.<span class="green-span-mobile">direct</span> - цифровой
                сервис ресторатора
              </p>
              <p class="content-text-mobile-1">
                Мобильное приложение вашего ресторана на IOS и Android
              </p>
              <ul class="content-text-mobile-2">
                <li>доставка</li>
                <li>система лояльности</li>
                <li>QR-оплата в зале</li>
                <li>QR-меню</li>
                <li>Аналитика и отчетность</li>
              </ul>
            </div>
            <div class="btn-container-mobile">
              <button class="btn-green-mobile">
                <a
                  class="content-text-button-mobile"
                  href="https://api.whatsapp.com/send?phone=77017224666&text=Здравствуйте! Мне интересны подробности возможностей сервиса Ozo.direct."
                  target="_blank"
                >
                  Связаться </a
                ><img src="../assets/phone-call.svg" class="pl-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <div class="background-image">
        <!-- <img class="bg" src="../assets/ozo_landing.png" /> -->
      </div>
      <div class="content-container">
        <div>
          <div class="logo-container">
            <img class="logo-ozo" src="../assets/OZO_landing_logo.svg" />
          </div>
        </div>
        <div style="margin-top: 13.4vh">
          <p class="desc-header">
            Ozo.<span class="green-span">direct</span> - цифровой сервис
            ресторатора
          </p>
          <p class="content-text">
            Мобильное приложение вашего ресторана на IOS и Android
          </p>
          <ul class="content-text">
            <li>доставка</li>
            <li>система лояльности</li>
            <li>QR-оплата в зале</li>
            <li>QR-меню</li>
            <li>Аналитика и отчетность</li>
          </ul>
        </div>
        <button class="btn-green">
          <a
            class="content-text-button"
            href="https://api.whatsapp.com/send?phone=77019292169&text=Здравствуйте! Мне интересны подробности возможностей сервиса Ozo.direct."
            target="_blank"
          >
            Связаться </a
          ><img src="../assets/phone-call.svg" class="pl-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
.section {
  height: 100vh;
  width: 100vw;
}
main {
  /* background-image: url('../assets/заглушка.png'); */
  background-size: 1980px 1000px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.background-image {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("../assets/ozo_landing.png");
  background-size: 1980px 1000px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg {
  width: 100vw;
  height: 100vh;
}
.content-container {
  position: relative;
  z-index: 100;
  top: 5.4vh;
  left: 10.41vw;
  max-width: 29.21vw;
}
.desc-header {
  color: #fff;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.green-span {
  color: #00b800;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.content-text {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 2.407vh;
}
.btn-green {
  display: inline-flex;
  padding: 18px 37px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px 15px 0px 15px;
  background: #00b800;
  margin-top: 2.407vh;
}
.logo-container {
  width: 100%;
  max-width: 6.25vw;
}
.logo-ozo {
  width: 100%;
}
.content-text-button {
  color: #fff !important;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.background-image-mobile {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("../assets/ozo_landing-mobile.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
}
.bg-mobile {
  width: 100vw;
  height: 100vh;
}
.bg-mobile-1 {
  width: 100vw;
  height: 100vh;
}
.content-container-mobile {
  /* position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 16px;
  margin-top: 15vh; */
  /* height: 77vh; */
  /* justify-content: space-between; */
  display: flex;
  flex-direction: column;
  /* height: 81%; */
  height: 100%;
  padding: 16px;
  margin-top: 7vh;
}
.content-container-mobile-2 {
  display: flex;
  /* padding: 16px; */
  flex-direction: column;
  justify-content: space-between;
  height: 74vh;
  /* height: 84%; */
}
.btn-container-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 77px; */
}
.desc-header-mobile {
  color: #fff;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.green-span-mobile {
  color: #00b800;
  font-family: Inter;
  font-size: 27px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
.content-text-mobile-2 {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: lowercase;
}
.content-text-mobile-1 {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn-green-mobile {
  display: inline-flex;
  padding: 18px 37px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 15px 15px 0px 15px;
  background: #00b800;
  /* margin-top: 2.407vh; */
}
.content-text-button-mobile {
  color: #fff !important;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
.logo-container-mobile {
    position: absolute;
    top: 16px;
    right: 16px;
}
.logo-ozo-mobile {
  max-width: 93px;
    width: 100%;
}
@media screen and (min-width: 600px) and (max-width: 1263px) {
  .background-image-mobile {
    position: absolute;
    background-image: url("../assets/ozo_landing-tablet.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bg-mobile {
    width: 100vw;
    height: 100vh;
  }
  .bg-mobile-1 {
    display: none;
  }
  .bg-mobile-1 {
    width: 100vw;
    height: 100vh;
  }
  .content-container-mobile {
    /* position: relative; */
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 32px;
    margin-top: 15vh;
    max-width: 67.59vw;
  }
  .content-container-mobile-2 {
    display: flex;
    /* padding: 16px; */
    flex-direction: column;
    height: 80vh;
    /* height: 84%; */
    justify-content: flex-start;
}
  .btn-container-mobile {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 77px;
  }
  .desc-header-mobile {
    color: #fff;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .green-span-mobile {
    color: #00b800;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .content-text-mobile-2 {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .content-text-mobile-1 {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-green-mobile {
    display: inline-flex;
    padding: 18px 37px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 15px 15px 0px 15px;
    background: #00b800;
    margin-top: 2.407vh;
  }
  .content-text-button-mobile {
    color: #fff !important;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .logo-container-mobile {
    max-width: 100px;
    width: 100%;
    position: absolute;
    top: 44px;
    left: 34px;
  }
  .logo-ozo-mobile {
    width: 93px;
    height: 85px;
  }
}

@media screen and(min-width: 576px) {
  .background-image-mobile {
    background: url("../assets/ozo_landing-576px.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* Средние девайсы («таблетки», >= 768px) */
@media screen and (min-width: 768px) {
  .background-image-mobile {
    background: url("../assets/ozo_landing-768px.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* Большие девайсы (десктопы, >= 992px) */
@media screen and (min-width: 992px) {
  .background-image-mobile {
    background: url("../assets/ozo_landing-992px.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media screen and (min-width: 1263px) {
  .background-image-mobile {
    background: url("../assets/ozo_landing-1263px.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media screen and (max-width: 1440px) {
  .background-image {
    background: url("../assets/ozo_landing-1440px.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media screen and (max-width: 1728px) {
  .background-image {
    background: url("../assets/ozo_landing-1728px.png");
    background-size: 100% 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media screen and (max-height: 500px) {
  .background-image-mobile {
    background: url("../assets/ozo_landing-height.png");
    background-size: 100% 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .desc-header-mobile {
    color: #fff;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .green-span-mobile {
    color: #00b800;
    font-family: Inter;
    font-size: 27px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
  }
  .content-text-mobile-2 {
    color: #fff;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: lowercase;
  }
  .content-text-mobile-1 {
    color: #fff;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .btn-green-mobile {
    display: inline-flex;
    padding: 18px 37px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 15px 15px 0px 15px;
    background: #00b800;
    margin-top: 2.407vh;
  }
  .content-text-button-mobile {
    color: #fff !important;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
  .logo-container-mobile {
    max-width: 54px;
    width: 100%;
    position: absolute;
    top: 16px;
    left: 90vw;
  }
  .logo-ozo-mobile {
    max-width: 93px;
    width: 100%;
  }
  .content-container-mobile {
    /* position: relative; */
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0 32px;
    margin-top: 8vh;
  }
}
/* position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 0 32px;
    margin-top: 10vh;
    max-width: 657px;
} */
/* .logo-container-mobile {
    max-width: 54px;
    width: 100%;
    position: absolute;
    top: 16px;
    /* max-width: 21.02vw; */
/* width: 100%; */
/* position: absolute; */
/* top: 32px; */
/* right: 15px; */
/* } */
@media screen and (min-height: 500px) and (max-height: 820px) and (min-width: 800px) {
  .background-image-mobile {
    background: url(/img/ozo_landing-992px.b717890e.png);
    background-size: 100% 100vh;
    background-position: 25% 85%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content-container-mobile {
    /* position: relative; */
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 32px;
    margin-top: 15vh;
    max-width: 62.59vw;
  }
}
@media screen and (max-width:391px) {
  .content-container-mobile-2 li{
  width: 200px;
} 
.content-text-mobile-1 {
  /* width: 222px; */
}
.content-container-mobile {
  margin-top: 0vh;
}
.content-container-mobile-2 {
    display: flex;
    /* padding: 16px; */
    flex-direction: column;
    justify-content: space-between;
    height: 82vh;
    /* height: 84%; */
}
}
@media screen and (max-width:420px) {
  .desc-header-mobile {
  max-width: 253px;
  width: 100%;
} 
}
</style>
